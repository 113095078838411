import styled from "styled-components";
import { toRem } from "utils/mixins";

export const MapContainer = styled.div`
  width: 100%;
  height: ${toRem(400)};
  margin: 0 !important;
  position: relative;
  outline: none;
  * {
    outline: none;
    margin: 0 !important;
    position: relative;
    max-height: ${toRem(400)};
  }
`;

export const Address = styled.a`
  display: block;
  margin-top: 10px;
  text-decoration: underline;
  font-weight: bold;
`;
