import React, { useState } from "react";

import { useForm } from "react-hook-form";
import axios from "axios";

import { green, white } from "theme/colors";
import Button from "components/button";
import { ContactsForm } from "./Form.styles";
import Popup from "components/popup";
import { useEffect } from "react";

const Form = (props) => {
  const { formEmailContacto, place } = props;
  //let data = lang === "EN" ? en.form : pt.form;

  const { register, errors, handleSubmit } = useForm();
  const [contact, setContact] = useState({
    chasLicense: "",
    chasMoto: ""
  });

  const [response, setResponse] = useState({
    type: "",
    message: "Um momento ..."
  });

  const [isOpen, setisOpen] = useState(false);

  const handleClick = () => {
    setisOpen(!isOpen);
  };

  const handleChange = (e) => {
    // console.log(contact);
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const clearForm = () => {
    document.getElementById("cform").reset();
    setContact({
      chasLicense: false,
      chasMoto: false
    });
    var ele = document.querySelectorAll(".media-checkbox input");
    for (var i = 0; i < ele.length; i++) ele[i].checked = false;
  };

  const onSubmit = async (e) => {
    handleClick();
    sendMessage();
  };

  //console.log("asd", formEmailContacto);
  const sendMessage = () => {
    setResponse({
      type: "loading"
    });
    //console.log(contact);
    var contactsFormData = new FormData();
    contactsFormData.append("cformEmail", formEmailContacto);
    contactsFormData.append("cplace", place);
    contactsFormData.append("cfname", document.querySelector("#cfname").value);
    contactsFormData.append("clname", document.querySelector("#clname").value);
    contactsFormData.append("cphone", document.querySelector("#cphone").value);
    contactsFormData.append("cemail", document.querySelector("#cemail").value);
    contactsFormData.append(
      "cbirthday",
      document.querySelector("#cbirthday").value
    );
    contactsFormData.append(
      "cpostalcode",
      document.querySelector("#cpostalcode").value
    );
    contactsFormData.append("chasLicense", contact.chasLicense ? "Sim" : "Não");
    contactsFormData.append("chasMoto", contact.chasMoto ? "Sim" : "Não");
    contactsFormData.append("cyear", document.querySelector("#cyear")?.value);
    contactsFormData.append("cbrand", document.querySelector("#cbrand")?.value);
    contactsFormData.append("cmodel", document.querySelector("#cmodel")?.value);
    contactsFormData.append(
      "csubject",
      document.querySelector("#csubject").value
    );
    contactsFormData.append(
      "cmessage",
      document.querySelector("#cmessage").value
    );

    var config = {
      method: "post",
      url: `https://admin.motospazio.pt/wp-json/contact-form-7/v1/contact-forms/128/feedback`,
      headers: {
        "Content-Type": "application/json"
      },
      data: contactsFormData
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "mail_sent") {
          setResponse({
            type: "success",
            message:
              "Obrigado pela tua mensagem. Vamos tentar responder assim que possível."
          });
          clearForm();
        } else {
          // console.log(response, contactsFormData);
          setResponse({
            type: "error",
            message:
              "Algo de errado aconteceu. Tenta outra vez por favor. Senão envia-nos diretamente um email."
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
        setResponse({
          type: "error",
          message:
            "Algo de errado aconteceu. Tenta outra vez por favor. Senão envia-nos diretamente um email."
        });
      });
  };

  function iOS() {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod"
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }

  useEffect(() => {
    if (props.title) {
      document.querySelector("#csubject").value = props.title;
    }
  }, [props.title]);
  return (
    <>
      <Popup
        show={isOpen}
        toggle={() => setisOpen()}
        type={response.type}
        message={response.message}
      />
      <ContactsForm>
        <form
          className="container section-form"
          id="cform"
          onSubmit={handleSubmit(onSubmit)}
        >
          <h3>Formulário de Contacto</h3>
          <div className="section-form-row">
            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="cfname">Nome *</label>
              </div>
              <input
                id="cfname"
                name="cfname"
                type="text"
                ref={register({ required: true, minLength: 2 })}
              />
              {errors.cfname?.type === "required" && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
              {errors.cfname?.type === "minLength" && (
                <p className="error-message">Nome inválido.</p>
              )}
            </div>

            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="clname">Apelido *</label>
              </div>
              <input
                id="clname"
                name="clname"
                type="text"
                // onChange={handleChange}
                ref={register({ required: true, minLength: 2 })}
              />
              {errors.clname?.type === "required" && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
              {errors.clname?.type === "minLength" && (
                <p className="error-message">Apelido inválido.</p>
              )}
            </div>
          </div>

          <div className="section-form-row">
            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="cphone">Contacto Telefónico *</label>
              </div>
              <input
                id="cphone"
                name="cphone"
                maxLength={9}
                type="tel"
                //onChange={handleChange}
                ref={register({ required: true, pattern: /[0-9]{9}$/ })}
              />
              {errors.cphone?.type === "required" && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
              {errors.cphone?.type === "pattern" && (
                <p className="error-message">Precisamos de um número válido.</p>
              )}
            </div>

            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="cemail">E-mail *</label>
              </div>
              <input
                id="cemail"
                name="cemail"
                type="text"
                //onChange={handleChange}
                ref={register({
                  required: true,
                  pattern: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/
                })}
              />
              {errors.cemail?.type === "required" && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
              {errors.cemail?.type === "pattern" && (
                <p className="error-message">Precisamos de um e-mail válido.</p>
              )}
            </div>
          </div>

          <div className="section-form-row">
            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="cbirthday">Data de Nascimento *</label>
              </div>
              <input
                id="cbirthday"
                name="cbirthday"
                type="text"
                placeholder={"dd/mm/aaaa"}
                onFocus={() => {
                  if (!iOS()) {
                    document.querySelector("#cbirthday").type = "date";
                  }
                }}
                onBlur={() => {
                  return !document.querySelector("#cbirthday").value
                    ? (document.querySelector("#cbirthday").type = "text")
                    : "";
                }}
                //onChange={handleChange}
                ref={register({ required: true })}
              />
              {errors.cbirthday?.type === "required" && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
              {errors.cbirthday?.type === "pattern" && (
                <p className="error-message">Idade inválida.</p>
              )}
            </div>

            <div className="section-form-field">
              <div className="section-form-field-up">
                <label htmlFor="cpostalcode">Código Postal *</label>
              </div>
              <input
                id="cpostalcode"
                placeholder={"0000-000"}
                name="cpostalcode"
                type="text"
                //onChange={handleChange}
                ref={register({ required: true })}
              />
              {errors.cpostalcode?.type === "required" && (
                <p className="error-message">Campo Obrigatório</p>
              )}
              {errors.cpostalcode?.type === "pattern" && (
                <p className="error-message">
                  Precisamos de um código postal válido.
                </p>
              )}
            </div>
          </div>

          <div className="section-form-row">
            <div className="section-form-field section-form-field--checks">
              <div className="section-form-field-check">
                <p>Tem Carta de Moto *</p>
                <div className="section-form-field-check-div">
                  <label className="media-checkbox">
                    <input
                      type="radio"
                      name="chasLicense"
                      ref={register({ required: true })}
                      onChange={() => {
                        setContact({ ...contact, chasLicense: true });
                      }}
                    />
                    <span className="checkmark" />
                    Sim
                  </label>
                  <label className="media-checkbox">
                    <input
                      type="radio"
                      name="chasLicense"
                      ref={register({ required: true })}
                      onChange={() =>
                        setContact({ ...contact, chasLicense: false })
                      }
                    />
                    <span className="checkmark" />
                    Não
                  </label>
                </div>
                {errors.chasLicense?.type === "required" && (
                  <p className="error-message">Campo Obrigatório.</p>
                )}
              </div>

              <div className="section-form-field-check">
                <p>Possui Moto *</p>
                <div className="section-form-field-check-div">
                  <label className="media-checkbox">
                    <input
                      type="radio"
                      name="chasMoto"
                      ref={register({ required: true })}
                      onChange={() =>
                        setContact({ ...contact, chasMoto: true })
                      }
                    />
                    <span className="checkmark" />
                    Sim
                  </label>
                  <label className="media-checkbox">
                    <input
                      type="radio"
                      name="chasMoto"
                      ref={register({ required: true })}
                      onChange={() =>
                        setContact({ ...contact, chasMoto: false })
                      }
                    />
                    <span className="checkmark" />
                    Não
                  </label>
                </div>
                {errors.chasMoto?.type === "required" && (
                  <p className="error-message">Campo Obrigatório.</p>
                )}
              </div>
              <div className="section-form-field-errors"></div>
            </div>
            {contact.chasMoto && (
              <div className="section-form-field">
                <div className="section-form-field-up">
                  <label htmlFor="cyear">Ano de Construção *</label>
                </div>
                <input
                  id="cyear"
                  name="cyear"
                  type="text"
                  // onChange={handleChange}
                  ref={register({ required: true, pattern: /^(19|20)\d{2}$/ })}
                />
                {errors.cyear?.type === "required" && (
                  <p className="error-message">Campo Obrigatório.</p>
                )}
                {errors.cyear?.type === "pattern" && (
                  <p className="error-message">Precisamos de um ano válido.</p>
                )}
              </div>
            )}
          </div>
          {contact.chasMoto && (
            <div className="section-form-row">
              <div className="section-form-field">
                <div className="section-form-field-up">
                  <label htmlFor="cbrand">Marca *</label>
                </div>
                <input
                  id="cbrand"
                  name="cbrand"
                  type="text"
                  // onChange={handleChange}
                  ref={register({ required: true })}
                />
                {errors.cbrand && (
                  <p className="error-message">Campo Obrigatório.</p>
                )}
              </div>

              <div className="section-form-field">
                <div className="section-form-field-up">
                  <label htmlFor="cmodel">Modelo *</label>
                </div>
                <input
                  id="cmodel"
                  name="cmodel"
                  type="text"
                  ref={register({ required: true })}
                  //onChange={handleChange}
                />
                {errors.cmodel && (
                  <p className="error-message">Campo Obrigatório.</p>
                )}
              </div>
            </div>
          )}
          <div className="section-form-row">
            <div className="section-form-field section-form-field--full">
              <div className="section-form-field-up">
                <label htmlFor="csubject">Assunto *</label>
              </div>
              <input
                id="csubject"
                name="csubject"
                type="text"
                // onChange={handleChange}
                ref={register({ required: true })}
              />
              {errors.csubject && (
                <p className="error-message">Campo Obrigatório.</p>
              )}
            </div>
          </div>

          <div className="section-form-row">
            <div className="section-form-field section-form-field--full">
              <div className="section-form-field-up">
                <label htmlFor="cmessage">Comentários</label>
              </div>
              <textarea
                rows="4"
                id="cmessage"
                name="cmessage"
                type="text"
                //  onChange={handleChange}
              />
            </div>
          </div>

          <span>* CAMPOS OBRIGATÓRIOS</span>
          <div className="section-form-button">
            <Button
              text="Enviar"
              backgroundColor={green.default}
              color={white.default}
              minWidth
            />
          </div>
        </form>
      </ContactsForm>
    </>
  );
};

export default Form;
