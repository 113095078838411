import styled from "styled-components";

import { black, white } from "theme/colors";
import { breakpoints } from "utils/mixins";
import { green, red } from "../../../theme/colors";
import { toRem } from "../../../utils/mixins";

export const ContactsForm = styled.div`
  background-color: #414042;
  .section-form {
    color: ${white.default};
    padding: 50px 20px;
    h3 {
      color: ${white.default};
    }
    &-field {
      margin-bottom: 10px;
      input[type="date"] {
        height: 52px;
      }
      input {
        ::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #bebebe;
          font-weight: 300;
        }

        ::-moz-placeholder {
          /* Firefox 19+ */
          color: #bebebe;
          font-weight: 300;
        }

        :-ms-input-placeholder {
          /* IE 10+ */
          font-weight: 300;
          color: #bebebe;
        }

        :-moz-placeholder {
          /* Firefox 18- */
          font-weight: 300;
          color: #bebebe;
        }
      }
      label {
        color: ${white.default};
      }
      /* Customize the label (the container) */
      &--checks {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
      }
      &-errors {
        width: 100%;
        p {
          margin: 0;
          font-size: 12px !important;
          color: ${red.default};
          text-align: left;
        }
      }
      &-check {
        width: 50%;
        min-width: 200px;
        @media (max-width: 400px) {
          width: 100%;
        }
        p {
          line-height: 25px;
          margin: 0;
        }
        &-div {
          display: flex;
          margin-bottom: 1.6rem;

          .media-checkbox {
            display: flex;
            align-items: center;
            justify-content: space-around;
            position: relative;
            cursor: pointer;
            user-select: none;
            margin-right: 12px;
            p {
              font-size: ${toRem(11)};
              line-height: ${toRem(13)};
              letter-spacing: ${toRem(0.55)};
            }
            a {
              font-size: ${toRem(11)};
              line-height: ${toRem(13)};
              text-decoration: underline;
            }

            &--red {
              span {
                color: red;
              }
            }

            input[type="radio"] {
              position: absolute;
              opacity: 0;
              cursor: pointer;
              height: 0;
              width: 0;
            }
          }

          .checkmark {
            top: 0;
            left: 0;
            height: 52px;
            width: 52px;
            background-color: ${white.dark};
            position: relative;
            margin-right: 12px;
            &::after {
              opacity: 0;
              content: "✓";
              left: 50%;
              transform: translateX(-50%);
              top: 0;
              line-height: 52px;
              font-size: 52px;
              color: ${green.default};
              position: absolute;
            }
          }

          .media-checkbox {
            &:hover input ~ .checkmark {
              background-color: ${white.dark};
            }
          }

          .media-checkbox input {
            &:checked ~ .checkmark {
              &::after {
                opacity: 1;
              }
            }
          }

          button {
            span {
              display: block;
              font-size: 18px;
              text-transform: uppercase;
              height: 52px;
              line-height: 52px;
              margin-right: 10px;
              padding-left: 70px;
              position: relative;
              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                background-color: ${white.default};
                width: 52px;
                height: 52px;
              }
            }
            &.active {
              span {
                &::before {
                  content: "✖";
                  font-size: 40px;
                  color: ${black.default};
                }
              }
            }
          }
        }
      }
    }
  }
  @media ${breakpoints.tabletSpec} {
    .section-form {
      &-field {
        &--checks {
          justify-content: space-between;
        }
      }
    }
  }
`;
