import styled from "styled-components";

import { black, white } from "theme/colors";

export const PopupContainer = styled.div`
  display: ${(props) => (props.show ? "flex" : "none")};
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 155;
  .LoaderBalls {
    position: relative;
  }
  .message-container {
    background-color: ${white.default};
    padding: 80px;
    position: relative;
    max-width: 500px;
    border: ${(props) =>
      props.borderColor ? `5px solid ${props.borderColor}` : null};
    &-close {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 25px;
    }
    p {
      background-color: ${black.default};
    }
  }
`;
