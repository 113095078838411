import styled from "styled-components";

import { black, white } from "theme/colors";
import { breakpoints } from "utils/mixins";

export const ContactsContainer = styled.div`
  background-color: #d1d2d3;
  padding: 50px 0;
  .container {
    * {
      text-align: center;
      margin: 10px 0;
    }
    .contacts-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        margin: 10px 15px;
        padding: 5px 5px;
        border-radius: 50px;
        background-color: ${black.default};
        color: ${white.default};
        span {
          margin: 0 25px;
        }
        img {
          margin: 0;
          background-color: ${white.default};
          padding: 7px;
          width: 35px;
          border-radius: 100%;
        }
      }
      small {
        font-size: 13px;
        width: 100%;
      }
    }
  }
  @media ${breakpoints.tabletSpec} {
  }
`;
