import React, { useState } from "react";

import { useForm } from "react-hook-form";
import axios from "axios";

import { green, white } from "theme/colors";
import Button from "components/button";
import Popup from "components/popup";

const Form = (props) => {
  const { formEmailServicos, place } = props;
  //let data = lang === "EN" ? en.form : pt.form;

  const { register, errors, handleSubmit } = useForm();

  const [response, setResponse] = useState({
    type: "",
    message: "Um momento ..."
  });

  const [isOpen, setisOpen] = useState(false);

  const handleClick = () => {
    setisOpen(!isOpen);
  };

  // const handleChange = (e) => {
  // };

  const clearForm = () => {
    document.getElementById("formS").reset();
  };

  const onSubmit = async (e) => {
    handleClick();
    sendMessage();
  };
  //console.log(place);
  const sendMessage = () => {
    setResponse({
      type: "loading"
    });
    var contactsFormData = new FormData();
    contactsFormData.append("ssFormEmail", formEmailServicos);
    contactsFormData.append("ssPlace", place);
    contactsFormData.append(
      "ssfname",
      document.querySelector("#ssfname").value
    );
    contactsFormData.append(
      "sslname",
      document.querySelector("#sslname").value
    );
    contactsFormData.append(
      "ssphone",
      document.querySelector("#ssphone").value
    );
    contactsFormData.append(
      "ssemail",
      document.querySelector("#ssemail").value
    );
    contactsFormData.append(
      "sspostalcode",
      document.querySelector("#sspostalcode").value
    );
    contactsFormData.append("sscity", document.querySelector("#sscity").value);
    contactsFormData.append(
      "ssbrand",
      document.querySelector("#ssbrand").value
    );
    contactsFormData.append(
      "ssmodel",
      document.querySelector("#ssmodel").value
    );
    contactsFormData.append(
      "ssbuildyear",
      document.querySelector("#ssbuildyear").value
    );
    contactsFormData.append(
      "ssquadro",
      document.querySelector("#ssquadro").value
    );
    contactsFormData.append(
      "ssmessage",
      document.querySelector("#ssmessage").value
    );

    var config = {
      method: "post",
      url: `https://admin.motospazio.pt/wp-json/contact-form-7/v1/contact-forms/390/feedback`,
      headers: {
        "Content-Type": "application/json"
      },
      data: contactsFormData
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === "mail_sent") {
          setResponse({
            type: "success",
            message:
              "Obrigado pela tua mensagem. Vamos tentar responder assim que possível."
          });
          clearForm();
        } else {
          //console.log(response, contactsFormData);
          setResponse({
            type: "error",
            message:
              "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
          });
        }
      })
      .catch(function (error) {
        //console.log(error);
        setResponse({
          type: "error",
          message:
            "Algo de errado aconteceu. Tenta outra vez por favor. Senão manda-nos diretamente um email."
        });
      });
  };
  return (
    <>
      <Popup
        show={isOpen}
        toggle={() => setisOpen()}
        type={response.type}
        message={response.message}
      />
      <form
        className="container section-form"
        id="formS"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="section-form-row">
          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssfname">Nome *</label>
            </div>
            <input
              id="ssfname"
              name="ssfname"
              type="text"
              // onChange={handleChange}
              ref={register({ required: true, minLength: 2 })}
            />
            {errors.ssfname?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.ssfname?.type === "minLength" && (
              <p className="error-message">Nome inválido.</p>
            )}
          </div>

          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="sslname">Apelido *</label>
            </div>
            <input
              id="sslname"
              name="sslname"
              type="text"
              // onChange={handleChange}
              ref={register({ required: true, minLength: 2 })}
            />
            {errors.sslname?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.sslname?.type === "minLength" && (
              <p className="error-message">Apelido inválido.</p>
            )}
          </div>
        </div>

        <div className="section-form-row">
          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssphone">Contacto Telefónico *</label>
            </div>
            <input
              id="ssphone"
              name="ssphone"
              maxLength={9}
              type="tel"
              //onChange={handleChange}
              ref={register({ required: true, pattern: /[0-9]{9}$/ })}
            />
            {errors.ssphone?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.ssphone?.type === "pattern" && (
              <p className="error-message">Precisamos de um número válido.</p>
            )}
          </div>

          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssemail">E-mail *</label>
            </div>
            <input
              id="ssemail"
              name="ssemail"
              type="text"
              //  onChange={handleChange}
              ref={register({
                required: true,
                pattern: /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/
              })}
            />
            {errors.ssemail?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.ssemail?.type === "pattern" && (
              <p className="error-message">Precisamos de um e-mail válido.</p>
            )}
          </div>
        </div>

        <div className="section-form-row">
          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="sspostalcode">Código Postal *</label>
            </div>
            <input
              id="sspostalcode"
              placeholder="0000-000"
              name="sspostalcode"
              maxLength={8}
              type="text"
              //  onChange={handleChange}
              ref={register({ required: true, pattern: /\d{4}[\-]\d{3}?/ })}
            />
            {errors.sspostalcode?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.sspostalcode?.type === "pattern" && (
              <p className="error-message">
                Precisamos de um código postal válido.
              </p>
            )}
          </div>

          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="sscity">Localidade *</label>
            </div>
            <input
              id="sscity"
              name="sscity"
              type="text"
              // onChange={handleChange}
              ref={register({ required: true })}
            />
            {errors.sscity && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
          </div>
        </div>

        <div className="section-form-row">
          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssbrand">Marca da Moto *</label>
            </div>
            <input
              id="ssbrand"
              name="ssbrand"
              type="text"
              //   onChange={handleChange}
              ref={register({ required: true })}
            />
            {errors.ssbrand && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
          </div>

          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssmodel">Modelo da Moto *</label>
            </div>
            <input
              id="ssmodel"
              name="ssmodel"
              type="text"
              //   onChange={handleChange}
              ref={register({ required: true })}
            />
            {errors.ssmodel && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
          </div>
        </div>

        <div className="section-form-row">
          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssbuildyear">Ano de Construção *</label>
            </div>
            <input
              id="ssbuildyear"
              name="ssbuildyear"
              type="text"
              //   onChange={handleChange}
              ref={register({ required: true, pattern: /^(19|20)\d{2}$/ })}
            />
            {errors.ssbuildyear?.type === "required" && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
            {errors.ssbuildyear?.type === "pattern" && (
              <p className="error-message">Precisamos de um ano válido.</p>
            )}
          </div>

          <div className="section-form-field">
            <div className="section-form-field-up">
              <label htmlFor="ssquadro">Nº de Quadro *</label>
            </div>
            <input
              id="ssquadro"
              name="ssquadro"
              type="text"
              //  onChange={handleChange}
              ref={register({ required: true })}
            />
            {errors.ssquadro && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
          </div>
        </div>

        <div className="section-form-row">
          <div className="section-form-field section-form-field--full">
            <div className="section-form-field-up">
              <label htmlFor="ssmessage">Observações *</label>
            </div>
            <textarea
              rows="4"
              id="ssmessage"
              name="ssmessage"
              type="text"
              //   onChange={handleChange}
              ref={register({ required: true })}
            />
            {errors.ssmessage && (
              <p className="error-message">Campo Obrigatório.</p>
            )}
          </div>
        </div>

        <span>* CAMPOS OBRIGATÓRIOS</span>
        <div className="section-form-button">
          <Button
            text="Enviar"
            backgroundColor={green.default}
            color={white.default}
            minWidth
          />
        </div>
      </form>
    </>
  );
};

export default Form;
