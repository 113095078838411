import styled from "styled-components";

export const ServicesContainer = styled.div`
  .section-banner {
    min-height: ${(props) => (props.isConc ? "0" : "")};
    h2 {
      color: ${(props) => (props.isConc ? "black" : "")};
      position: ${(props) => (props.isConc ? "relative" : "")};
    }
  }
  .section-content-icon {
    height: 10rem;
    width: auto;
  }
`;
