import React from "react";

import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import { MapContainer, Address } from "./GoogleMaps.styles";
import HTMLReactParser from "html-react-parser";

const GoogleMaps = (props) => {
  //PROPS
  const {
    latitude,
    longitude,
    google,
    address,
    linkMapsConcessionario
  } = props;
  //PROPS

  const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%"
  };


  return (
    <>
      <MapContainer>
        <Map
          containerStyle={containerStyle}
          google={google}
          initialCenter={{
            lat: Number(latitude),
            lng: Number(longitude)
          }}
          zoom={15}
        >
          <Marker
            onClick={() => {
              window.open(linkMapsConcessionario, "_blank");
            }}
            name={"Motospazio"}
            position={{ lat: Number(latitude), lng: Number(longitude) }}
            icon={{
              url: "/images/marker.png",
              anchor: new google.maps.Point(56.5, 148),
              scaledSize: new google.maps.Size(113, 148)
            }}
          />
        </Map>
      </MapContainer>
      <Address href={linkMapsConcessionario} target="_blank">
        {address && HTMLReactParser(address)}
      </Address>
    </>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyBEAUUVhcvrp8By_lDpS8CsYZO2YNz8wkw"
})(GoogleMaps);
