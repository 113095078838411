import React from "react";

import Img from "gatsby-image";
import HTMLReactParser from "html-react-parser";

import { ServicesContainer } from "./Services.styles";
import Form from "./form/Form";

const Services = (props) => {
  const {
    formEmailServicos,
    data: {
      serviceText,
      serviceTitle,
      serviceImagem,
      serviceTitleForm,
      serviceIcon
    },
    place
  } = props;

  return (
    <ServicesContainer
      className="section section--unlimited"
      id="servico"
      isConc={props.isConc}
    >
      {props.isConc ? (
        <h2>{serviceTitle}</h2>
      ) : (
        <div className="section-banner">
          <Img
            fluid={serviceImagem?.localFile?.childImageSharp?.fluid}
            alt={serviceImagem?.altText}
          />
          <h2>{serviceTitle}</h2>
        </div>
      )}
      <div className="container section-content">
        {serviceText && HTMLReactParser(serviceText)}

        <img
          className="section-content-icon"
          src={serviceIcon?.localFile.url}
          alt={serviceIcon?.altText}
        />
        <h3>{serviceTitleForm}</h3>

        <Form formEmailServicos={formEmailServicos} place={place} />
      </div>
    </ServicesContainer>
  );
};

export default Services;
