import React from "react";
import { green, red, white } from "theme/colors";

import { PopupContainer } from "./Popup.styles";

const Popup = (props) => {
  const { show, toggle, type, message } = props;
  return (
    <PopupContainer
      show={show}
      borderColor={
        type === "success"
          ? green.default
          : type === "error"
          ? red.default
          : white.default
      }
    >
      {type === "loading" ? (
        <div className="LoaderBalls">
          <div className="LoaderBalls__item" />
          <div className="LoaderBalls__item" />
          <div className="LoaderBalls__item" />
        </div>
      ) : (
        <div className="message-container">
          <button
            className="message-container-close"
            onClick={() => toggle(!show)}
          >
            x
          </button>
          {message}
        </div>
      )}
    </PopupContainer>
  );
};

export default Popup;
